import * as React from "react"
import Head from 'next/head'
import Image from 'next/image'
import * as styles from '../styles/pages/home.module.scss'

const IndexPage = () => {
  return (
    <div>
      <Head>
        <title>Finn Kennedy Portfolio</title>
        <meta name="description" content="The last developer you will need" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={`${styles.main} flex flex-col min-h-screen items-center justify-center`}>
        <h1 className='lg:text-5xl text-2xl mb-5 text-center font-bold'>Welcome to my portfolio</h1>
        <h2 className='lg:text-2xl text-lg text-center'>I am currently working on it so please be patient</h2>
      </main>

      <footer className={styles.footer}>

      </footer>
    </div>
  )
}

export default IndexPage
